/* General Styles */
.main-content {
  display: flex;
}

.layout {
    position: relative;
}

option {
    background-color: #fff;
}

.homepage {
    padding: 20px 20px 0px 20px;
    overflow: hidden; /* Prevent overflow issues */
    color: #444; /* Soft dark gray for general text */
    display: flex; /* Use flexbox to align elements */
    flex-direction: column; /* Keep the layout vertical for desktop */
    flex: 1;
}


/* Why Choose Us */
.why-choose-us {
    padding:20px;
    text-align: center;
}

.why-choose-us h2 {
    margin-bottom: 20px;
 /* Larger font size for emphasis */
    font-weight: 700; /* Bold text */
}

.why-choose-us p {
    margin-bottom: 30px; /* Increased bottom margin for spacing */
 /* Slightly larger font for readability */
    line-height: 1.8; /* Improved line height for better readability */
}

.why-choose-us ul {
    list-style: none;
    padding: 0;
    max-width: 600px;
    margin: 0 auto;
}

.why-choose-us li {
    margin: 15px 0;
 /* Slightly larger font for readability */
    position: relative; /* For adding icons or styles */
}

.why-choose-us img {
    width: 100%; /* Responsive image */
    height: auto; /* Maintain aspect ratio */
    margin-top: 20px; /* Space above the image */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for the image */
}

.why-choose-us .cta-button, .bordered .cta-button {

  
}

/* Stocklist Section */
.stocklist-section {
    background-color: #f9f9f9;
    text-align: center;
    margin: 20px;
}

.stocklist-section h2 {
    color: #2c3e50;
}

/* Contact CTA Section */
.contact-cta {
    padding: 50px 0;
    background-color: #1da1f2;
    color: white;
    text-align: center;
    margin: 20px;
}

.contact-cta p {
    margin-bottom: 30px;
}

.contact-cta h2 {
    color: #fff;
}

.contact-cta .cta-button {
    background-color: #e67e22;
    color: white;
    padding: 12px 30px;
    text-decoration: none;
}

.cta-button a {
    text-decoration: none;
    color: #f5f5f5;
}

.contact-cta .cta-button:hover {
    background-color: #d35400;
    transform: scale(1.05);
}

/* Responsive Styles */
@media (max-width: 768px) {
 
    .why-choose-us h2,
    .stocklist-section h2 {
 /* Adjusted heading size */
    }

    .cta-button {
 /* Adjust button font size */
        padding: 10px 20px; /* Adjust button padding */
    }

    /* Testimonials Section */
    .testimonial {
 /* Adjust testimonial font size */
    }

    .contact-cta p {
 /* Smaller contact CTA text */
    }
}

.info-cards-container {
    display: grid;
    grid-template-columns: repeat(8, 1fr); /* Keep five columns */
    gap: 30px; /* Increased space between cards */
    padding: 20px; /* Padding around the container */
    grid-template-columns: repeat(4, 1fr);
    background-color: #fe9900;
    margin: 20px;
    
}

@media (max-width: 1000px){
    .info-cards-container {
        grid-template-columns: repeat(4, 1fr);
    }
}

.info-card {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Deeper shadow for more depth */
    text-align: center; /* Center align text */
    transition: transform 0.2s ease; /* Smooth scaling effect */
}

.info-card:hover {
    transform: translateY(-5px); /* Lift effect on hover */
}

.info-card-image {
    width: 100%; /* Make image responsive */
    height: auto; /* Maintain aspect ratio */
}

.info-card-title {
    margin-top: 10px; /* Space between image and text */
 /* Adjust font size for better readability */
    font-weight: 600; /* Bold text for emphasis */
    color: #2c3e50; /* Darker text color */
}



.car-dismantling {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px 40px;
    background-color: #f4f6f8;
    border-top: 1px solid #ddd;
  }
  
  .dismantling-content {
    flex: 1;
    padding-right: 20px;
    text-align: left;
    margin: 10px 0;
  }
  
  .dismantling-content h2 {
    color: #1e3a8a;

  }
  
  .dismantling-content p {
    color: #333;
    margin-top: 10px;

    line-height: 1.5;
  }
  
  .learn-more-btn {
    margin-top: 15px;
    padding: 10px 20px;
    border: none;
    background-color: #e67e22;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .learn-more-btn:hover {
    background-color: #d35400;
  }
  
  .dismantling-image img {
    max-width: 100%;
    height: auto;
  }

  .video-slider-section {
    width: 100%;
  }
  
  .video-slide video {
    width: 100%;
    height: auto;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .video-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 20px 7px;

  }
  
  .banner-video, .banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video/image covers the entire container */
  }
  
.label-cell {
  width: 50px;
}

.info-card-link {
    text-decoration: none;
    color: inherit; /* Inherit text color */
  }
 .step-card:hover   {
    border: solid .5px #f1892b;
    color: #f1892b; /* Highlighted title color */
  }

.info-card:hover .info-card-title {
    color: #f1892b; /* Highlighted title color when info-card is hovered */
}

.slick-dots {
    display: flex !important;
    justify-content: center;
    align-items: center;
    bottom: 3px !important;
}

.slick-dots li button:before {

}

 .slick-prev {
    left: 0 !important;
 }

 .slick-next {
    right: 0 !important;
 }
.slick-prev, .slick-next {
    z-index: 5;
   
}

.main-container {
    display: flex; /* Aligns the containers side by side */
    flex-wrap: wrap; /* this gonna fix the width issue for devices like ipad air */
    justify-content: space-between; /* Ensures equal spacing between them */
    gap: 10px;
}

.type-container, .make-container {
    flex: 1; /* Allows both containers to take equal space */
    box-sizing: border-box; /* Ensures padding and border are included in the width */
}



.title {

    font-weight: bold;
    margin-bottom: 10px;
    padding: 5px;
}

.grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Ensures 4 items per row */
    gap: 10px;
}

.grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #fff;
    text-align: center;
    cursor: pointer;
}

.grid-item img {
    width: 110px;
    margin-bottom: 5px;
}

.grid-item span {

}

.brands {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 items per row */
    gap: 10px;
}

.brand {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
    padding: 10px; /* Reduced padding for smaller size */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
 /* Reduced font size for brand text */
    cursor: pointer;
}

.brand img {
    max-width: 90px; /* Reduced size of brand logos */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 5px; /* Reduced margin */
}

.brand p {
    margin: 0;
 /* Reduced font size for brand text */
    text-transform: uppercase;
}


.ad-header-container {
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Align items vertically centered */
    padding: 20px; /* Padding around the header container */
    position: relative;
}

.pointer {
    position: absolute; /* Position the pointer */
    top:48%; /* Center vertically */
    right: 0%; /* Position the pointer to the right */
    transform: translate(-50%, -50%); /* Offset the center */
 /* Size of the pointer */
    cursor: pointer; /* Pointer cursor */
    width: 25%;
    height: 50%;
}
.order-now , .pointer ,.cta-btn-link , .register-now-btn ,
 .eco-btn , .small-banner-btn , .security-btn , .dismantling-btn,
 .paypal-btn, .bank-btn, .sell-btn{
    background-color: transparent;
    color: transparent;
}

.cta-btn-link {
    position: absolute;
    bottom: 15%;
    left: 40%;
    width: 12%;
}

.register-now-btn {
    position: absolute;
    top: 69%;
    left: 62%;
    width: 30%;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    height: 20%;
}

.bank-btn {
    position: absolute;
    top: 34%;
    left: 19%;
    width: 10%;
}

.dismantling-btn {
    position: absolute;
    right: 8%;
    bottom: 12%;
    width: 13%;
    height: 7%;
}

.paypal-btn {
    position: absolute;
    left: 19%;
    bottom: 13%;
    width: 11%;
}
.order-now {
    position: absolute;
    bottom: 26%;
    width: 16%;
    left: 74%;
    height: 8%;
    
}

.security-btn {
    position: absolute;
    bottom: 16%;
    right: 10%;
    width: 14%;
    height: 7%;
}

.ad-banner {
    height: auto; /* Set height for the banner */
    flex: 1; /* Allow the banner to take available space */
    width: 100%;
    
}

.small-image-container {
    margin-left: 20px; /* Space between the banner and small image */
    position: relative;
}

.small-banner-container img {
    width: 100%;
}

.small-image {
    width: 250px; /* Set width for the small image */
    height: auto; /* Maintain aspect ratio */

}

.text-content {
    padding: 0 20px; /* Padding around the text content */
}

.icon-container {
    position: absolute; /* Position absolute to overlay the small image */
    top: 50%; /* Center vertically */
    right: 5%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Offset the center */
    text-align: center; /* Center text */
    z-index: 5;
}

.small-icon {
 /* Size of the hand pointer icon */
    color: #e67e22;
}

.small-icon , .click-text {
    text-shadow: 1px 1px 1px #fff;
}

.click-text {
    display: block; /* Make the text a block element */
    margin-top: 5px; /* Space between icon and text */
 /* Font size for the text */
    color: #e67e22; /* Text color */
    text-transform: uppercase;
}


.overlay {
    position: absolute; /* Position absolute to overlay the small image */
    top: 0; /* Align to the top */
    left: 0; /* Align to the left */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgba(0, 0, 0, 0.3); /* Black background with 50% opacity */
    z-index: 1; /* Ensure the overlay is above the image */
}

.image-slider-section {
    height:300px;
    overflow:hidden;
}

.slick-slide img {
    max-height: 300px;
}

@media  screen and (max-width:1200px) {
    .slick-slide img {
        height: 300px !important;
    }
    
}
.ad-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.ad-container {
    padding: 30px;
}

.bold-text {
    font-weight: bold; /* Makes the text bold */
 /* Increases the font size */
    text-align: center; /* Centers the text */
    margin: 10px 0; /* Adds some space above and below the text */

}

.bordered {
    border: 2px solid #f1892b; /* Adds a border with a bright, eye-catching orange color */
    padding: 15px; /* Adds space inside the border */
    margin-bottom: 20px; /* Adds space between each container */
    text-align: center;
    position: relative;
  }
  
  .small-banners {
    display: flex;
    gap: 10px;
    justify-content: center;
  }

  .bordered .cta-btn {
    position: absolute;
    top: 70%;
    right: 14%;
    background-color: #1da1f2;
    font-weight: bold;

  }

  .small-banner-container {
    position: relative;
    width: 100%;
  }

  .small-banner-btn img {
    width: 100%;
  }
  .small-banner-btn {
    position: absolute;
    top: 57%;
    left: 52%;

    width: 18%;
  }

  .sell-btn {
    position: absolute;
    bottom: 12%;
    right: 6%;
    width: 30%;
    height: 11%;

  }

  .small-banner-btn a {
    color: inherit;
    text-decoration: none;
  }

  .eco-btn {
    left: 78%;
    top: 80%;
    width: 21%;
    height: 12%;
  }

/* Useful links */
.usefulLinks_wrapper {
    margin: 0 20px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}

.usefulLinks_container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.header {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
}

.header h1 {

    text-shadow: 2px 2px 5px #1da1f2;
    display: inline-block;
    background: #fff;
    padding: 0 10px;
    position: relative;
    z-index: 1;
    left: 73px;
}

.wrapper , .newsupdates {
    margin: 0 20px;
}
.news-item-container {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    display: flex;
    justify-content: space-between;
}

.news-item-container img {
    width: 17%;
    object-fit: cover;
    position: absolute;
    right: 0;
}

.news-item {
 /* Increase font size */
    color: #333; /* Dark text color */
    margin: 10px 0; /* Space between news items */
    padding: 10px 20px; /* Padding around text */
    background-color: #ffffff; /* White background for news items */
    border-radius: 5px; /* Rounded corners for news items */
    transition: transform 0.2s; /* Smooth transition for hover effect */
    font-weight: bold;
    white-space: nowrap;
}

.title-img {
    width: 100%;
}

.usefulLinks_wrapper .header::before, .wrapper .header::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 5px;
    background-color: orange;
    z-index: 0;
}

.links {
    flex: 1;
    display: flex;
    gap: 20px; /* Increased space between items */
}

.links a {
    text-decoration: none;
    color: inherit;
    display: block; /* Make the link a block element */
    width: 100%; /* Ensure it takes full width */
    padding: 10px; /* Add padding for better click area */
    box-sizing: border-box; /* Include padding in width */
    white-space: nowrap; /* Prevent text from wrapping */
}

.links ul {
    list-style: none;
    padding: 0;
    margin: 0; /* Remove default margin */
}

.links ul li {

    color: #000;
    margin-bottom: 10px; /* Add bottom margin for spacing */
    font-weight: bold;
}

.image-usefulLinks_container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.image-usefulLinks_container img {
    max-width: 90%;
    height: auto;
}

@media screen and (max-width:1200px){
    .image-usefulLinks_container img {
        margin: 15px;
        max-width: 80%;
        object-fit: cover;
    }    

    .image-usefulLinks_container {
        align-items:unset;
    }

}