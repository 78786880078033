/* Force desktop styles for smaller screens */
p, ul li, ol li, .list-item, .services-text, .logistics-agent-info, .cta-link ,.bank-info-table td , .footer-inner a {
  font-size: clamp(1.25rem, 1.75vw, 1.625rem); /* Increased for better readability */
}

.logistics-agent-info, .services-text, .logistics-agent-info a {
  font-size: clamp(0.875rem, 2vw, 2rem) !important; /* As per your original request */
}

.links a {
  font-size: clamp(0.95rem, 1.3vw, 1.35rem); /* Slightly smaller for links */
}

h1 {
  font-size: clamp(2.125rem, 4.15vw, 3.125rem); /* Slightly decreased for balance */
}

h2 {
  font-size: clamp(1.75rem, 3.1vw, 2.375rem); /* Slightly smaller for hierarchy */
}

h3 {
  font-size: clamp(1.5rem, 2.5vw, 2rem); /* Slightly decreased for balance with h2 */
}

h4  , .bank-info-table th{
  font-size: clamp(1.25rem, 2.1vw, 1.5rem); /* Slightly smaller for readability */
}

h5  {
  font-size: clamp(1rem, 1.65vw, 1.25rem); /* Decreased for clarity */
}

h6 {
  font-size: clamp(0.875rem, 1.5vw, 1.125rem); /* Slightly smaller */
}

small, .top-bar span, .profile-sidebar-menus li {
  font-size: clamp(0.875rem, 1.25vw, 1.125rem); /* Slightly smaller */
}

button {
  font-size: clamp(0.95rem, 1.25vw, 1.125rem); /* Decreased for clarity */
}

blockquote {
  font-size: clamp(1.125rem, 2.25vw, 1.625rem); /* Slightly smaller for distinction */
}

caption {
  font-size: clamp(0.875rem, 1.25vw, 1.125rem); /* Decreased to match */
}

nav {
  font-size: clamp(0.875rem, 1.5vw, 1.25rem); /* Slightly smaller for navigation */
}

a, .nav-item, i {
  font-size: clamp(0.95rem, 1.35vw, 1.125rem); /* Slightly smaller for consistency */
  text-decoration: none;
  color: inherit;
}

td {
  font-size: clamp(0.95rem, 1.35vw, 1.125rem); /* Slightly smaller for table data */
}

th {
  font-size: clamp(1rem, 1.65vw, 1.25rem); /* Slightly smaller for table headings */
}

label {
  font-size: clamp(0.95rem, 1.35vw, 1.125rem); /* Slightly smaller for form labels */
}

input {
  font-size: clamp(0.95rem, 1.35vw, 1.125rem); /* Slightly smaller for form inputs */
}

@media (min-width: 1400px) {
  .intro-content p, .logistics-agent-info, .services-text, .logistics-agent-info a {
    font-size: clamp(1rem, 1.5vw, 1.5rem); /* 16px - 24px */
  }
  p, ul li, ol li, .list-item, .cta-link, .bank-info-table td, .footer-inner a , .grid-item span {
    font-size: clamp(1rem, 1.5vw, 1.5rem); /* 16px - 24px */
  }

  .links a {
    font-size: clamp(1.125rem, 1vw, 1.50rem);
  }

  .congo-agent-container .intro-content p {
    font-size: clamp(2rem, 2.25vw, 1.25rem) ;
  }
}



body {
  overflow-x:hidden ;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.header-container, .used-tires-page{

  max-width: 100%;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

    
@media screen and (max-width: 9999px) {
    .layout, .usedTiresForm-container, .car-details, .help-page , .footer-inner, .shipping-container , .top-bar, .profile-wrapper ,.namibia-agent-container, .congo-agent-container  {
        margin: 0 auto;
        width: 90%; /* Use percentage */
        max-width: 1540px; /* Set a max-width */
    }
    .header-bottom, .header-top, .local-services-banner {
        margin: 0 auto;
        width: 90%; /* Use percentage */
        max-width: 1480px; /* Set a max-width */
    }

    .top-bar {
        max-width: 1730px;
        width: 90%;
        margin: 0 auto;
    }

    .extra-info {
        margin: 0 -89px;
    }


}

@media screen and (max-width: 1760px) {
    .layout, .usedTiresForm-container, .car-details, .help-page , .footer-inner, .shipping-container ,.top-bar ,.profile-wrapper, .namibia-agent-container, .congo-agent-container  {
        margin: 0 auto;
        width: 90%; /* Use percentage */
        max-width: 1438px; /* Set a max-width */
    }
    .header-top, .header-bottom {
        width: 90%; /* Use percentage */
        max-width: 1460px; /* Set a max-width */
    }
}

@media screen and (max-width: 1530px) {
    .layout, .header-top, .local-services-banner, .usedTiresForm-container, .car-details, .help-page, .footer-inner, .shipping-container ,.top-bar , .profile-wrapper , .namibia-agent-container ,.congo-agent-container  {
        margin: 0 auto;
        width: 90%; /* Use percentage */
        max-width: 1330px; /* Set a max-width */
    }
    .header-bottom {
        width: 90%; /* Use percentage */
        max-width: 1330px; /* Set a max-width */
    }
}

@media screen and (max-width: 1500px) {
  .layout, .header-top, .local-services-banner, .usedTiresForm-container, .car-details, .help-page, .footer-inner, .shipping-container , .top-bar ,.profile-wrapper , .namibia-agent-container, .congo-agent-container {
      margin: 0 auto;
      width: 100%; /* Use percentage */
      max-width: 100%;
  }
  .header-bottom {
      width: 90%; /* Use percentage */
      max-width: 1330px; /* Set a max-width */
  }



  
}

@media screen and (min-width:1500px) {

    .terms-container, .asf, .whyChoose, .dismantling, .overview, .envirement-policy, .security-notice, .paypal, .howtopay, .aboutusedtires {
        margin: 0 !important;
      }
    
      .sidebar {
        padding: 0 !important;
      }
    
}



