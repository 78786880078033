/* CSS for the right sidebar container */
.right-sidebar {
  width: 300px;
  background-color: #fff;
  margin: 20px 5px 0px 0px;
  display: flex;
  flex-direction: column; 
  box-sizing: border-box; /* Include padding in the total dimensions */
}

/* Make right sidebar elements fill available space */
.right-sidebar > * {
  flex: 1; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.shipment-banner {
  margin-top: 20px;
}

.profile-btn , .contact-btn {
  position: absolute;
}

.profile-btn {
  left: 5%;
  top: 18%;
  width: 41%;
}

.contact-btn {
  top: 18%;
  right: 7%;
  width: 39%;
}
/* Optional: Images should adjust dynamically */
.right-sidebar img {
  max-width: 100%;
  height: auto;
  display: block;
}


.right-sidebar .account-container {
  flex-direction: column;
  padding: 0;
  background-color: unset;
  border: unset;
  box-shadow: unset;
  margin: 0 5px ;
}
.register-banner, .welcome-banner, .shipment-banner {
  position: relative;
} 

.sign-in-btn, .register-btn , .contact-btn ,.profile-btn , .shipping-btn {
  background-color: transparent;
  color: transparent;
}

.sign-in-btn {
  position: absolute;
  top: 83%;
  width: 100%;
  left: 0%;
}

.register-btn {
  position: absolute;
  top: 3%;
  width: 96%;
  height: 23%;
  left: 2%;
}

.welcome-banner {
  display: flex;
  justify-content: center;
}

.welcome-banner h2 {
  position: absolute;
  max-width: 73%;
  color: #fff;
  display: -webkit-box;          /* Create a flexible box */
  -webkit-box-orient: vertical;  /* Define the direction of the box */
  overflow: hidden;              /* Hide overflowed content */
  -webkit-line-clamp: 3;         /* Limits the content to 3 lines */
  text-overflow: ellipsis;
  text-transform: capitalize;
  font-size: 20px;
  top: 5%;
}


.shipping-btn {
  bottom: 11%;
  position: absolute;
  left: 25%;
  height: 13%;
  width: 49%;
}

.sign-in-btn {
    color: #FFf;
    /* font-weight: bold; */
    font-family: anton;
    background-color: #ff9900;
    text-transform: capitalize;
    font-size: 20px;
}

.sign-in-btn:hover {
  background-color: #1e3a8a;
}

/* Adding lines with pseudo-elements */
.sign-in-btn::before,
.sign-in-btn::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 10px;
  width: 100px; /* Adjust length of the lines as needed */
  background-color: #fff; /* Line color */
}

.sign-in-btn::before {
  left: 20px; /* Space between line and button */
}

.sign-in-btn::after {
  right: 20px; /* Space between line and button */
}

.sign-in-btn:hover::before,
.sign-in-btn:hover::after {
  background-color: #FFf; /* Line color on hover */
}