/* CarDismantling.css */

.carDismantling-container {
  display: flex;
  flex-direction: column;
  padding: 20px; /* Equivalent to p-6 */
  max-width: 1400px; /* Set a max width for larger screens */
  margin: auto; /* Center the container */
}

.carDismantling-container .sidebar-image {
  display: none; /* Hide sidebar image */
}

/* Image container style */
.image-container {
  width: 100%;
  margin: 0 auto; /* Center the image */
  overflow: hidden; /* Clip overflow */
}



/* Image style */
.image {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease; /* Smooth zoom effect */
}
.pdf-container {
  margin: 0px auto;
  padding: 20px;
  border-radius: 8px; /* Rounded corners for the container */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.pdf-container h1, .pdf-container h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.pdf-container h1 {
 /* Larger font size for main title */
}

.pdf-container h2 {
 /* Slightly smaller for subheadings */
}

.pdf-container ol {
  padding-left: 20px;
  list-style: none; /* Remove default list styling */
  counter-reset: item; /* Reset counter for custom numbering */
}

.pdf-container ol li, .cutting-style {
  margin-bottom: 15px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  position: relative;
  transition: background-color 0.3s, border-color 0.3s;
}

/*
.nose-cut , .half-cut ,.combination-cut{
  display: flex;
  flex-direction: column;
  align-items: center;
}
*/
.combination-cut  {
  align-items: flex-start;
}

.combination-cut img {
  align-self: center;
}
.cutting-style img {
  width: 50%;
  max-width: 100%;
  object-fit: contain;
}

.rear-cut-imgs {
  display: flex;
  align-items: center;
}



.nose-cut , .half-cut, .combination-cut , .rear-half{
  display: flex;

  justify-content: space-between;
  gap: 20px; /* Controls the space between text and image */
  flex-direction: row;
}

.rear-cut-imgs img {
  width: 85%;
}

/*

@media (min-width:1270px){
  .nose-cut , .half-cut, .combination-cut , .rear-half{
    display: flex;

    justify-content: space-between;
    gap: 20px; 
    flex-direction: row;
  }

  .rear-cut-imgs img {
    width: 85%;
  }
}
*/

.pdf-container ol li::before, .cutting-style ul li::before {
  content: counter(item) ". "; /* Custom counter for ordered list items */
  counter-increment: item; /* Increment counter for each list item */
  position: absolute;
  left: -40px; /* Positioning for the counter */
  top: 15px; /* Align with the text */
  font-weight: bold;
  color: #1da1f2; /* Color for the counter */
 /* Font size for the counter */
}

.pdf-container strong , .cutting-style h3 {
  color: #1da1f2; /* Color for strong text */
}

.pdf-container li:hover {
  background-color: #f1f1f1; /* Background color on hover */
  border-color: #ccc; /* Border color on hover */
}

.pdf-container li p {
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}

.pdf-container li strong {
  display: block; /* Make strong text a block element */
  margin-bottom: 5px; /* Space below strong text */
}

/* Ensure that the unordered list items are styled normally */
.pdf-container ul {
  padding-left: 20px; /* Indentation for unordered list */
  list-style-type: disc; /* Default bullet points for unordered list */
}

.howToOrder ul li {
  margin-bottom: 10px; /* Space between unordered list items */
  padding: 0; /* No padding for unordered list items */
  background: none; /* No background for unordered list items */
  border: none; /* No border for unordered list items */
  box-shadow: none; /* No shadow for unordered list items */
}

.pdf-container ul li:before {
  content: '' !important;
}

.cutting-style ul li {
  padding: 10px 15px; /* Padding for better spacing */
  transition: background-color 0.3s, border-color 0.3s; /* Smooth transition for hover effects */
}


.cutting-style p {
  text-align: left;
}

.image-container {
  position: relative;
}

.dismantling-shipping-btn {
  position: absolute;
  top: 69%;
  right: 16%;
  width: 37%;
  height: 9%;
  color: #fff;
  border: none;
  background-color: #465b9e;
  border-radius: 33px;
  box-shadow: -12px 13px 1px #333;
 }

 .dismantling-shipping-btn a {
  font-size: 35px !important;
 }

.dismantling-shipping-btn:hover {
  background-color: #596db0;
}