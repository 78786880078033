.bank-information {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05);
}

.bank-info-table {
  width: 100%; /* Full width */
  border-collapse: collapse; /* Remove space between borders */
}

.bank-info-table th {
  padding: 10px; /* Padding for table cells */
  border: 1px solid #ecf0f1; /* Border for table cells */
  text-align: center; /* Align text to the left */
}

.bank-info-table th {
  background-color: #f8f9fa; /* Light background for headers */
  color: #34495e; /* Header text color */
}

.bank-info-table td {
  color: #2c3e50; /* Data text color */
}


.notice {
  color: red;
  margin: 0;
}

.wise-info-container {
  display: flex;
  gap:20px;
}