.form-header {
    align-self: flex-start;
    width: 100%;
  }

  .usedTiresForm-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('../../public/images/TireBanner.jpg');
    background-size: cover;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: auto;
    position: relative;
    color: #fff;
  }

  .login-prompt, .welcome-message {
    z-index: 5;
  }

  .userName {
    color: #1da1f2;
    font-weight: bold;
  }
  .container-inner , .tire-selection-container , .form-header{
    z-index: 5;
  }

  .usedTiresForm-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 4;
  }
  
  
  .form-header {
    background-color: #003366;
    color: #fff;
    text-align: center;
  }

  .form-header div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .form-header .logo {
    width: 50px;
  }
  
  .container-inner {
    display: flex;
    justify-content: space-evenly;
    width: 95%;
    position: relative;
  }
  
  .form-container {
    padding: 20px;
    flex: 1;
    margin-top: 20px;
  }
  
  .form-row {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;
    gap: 40px;
  }
  
  .usedTiresForm-container .form-group {
    flex: 0 1 40%; /* Allow flexibility and set a base width */
    text-align: left;
    max-width: 45%; /* Prevent exceeding this width */
  }

  .form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1 fr); /* Two columns of equal width */
    gap: 20px; /* Space between grid items */
}


  
  .form-group label {
    margin-bottom: 5px;
    color: #fff;
    font-weight: bold;
  }
  

  .usedTiresForm-container .form-group input,
  .usedTiresForm-container .form-group select {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
   
  }

  select{
    -webkit-appearance: none; /* Remove default styling on iOS */ 
    -moz-appearance: none;/* Remove default styling on Firefox */
  }

  
  button[type="submit"] {
    background-color: #1da1f2;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 25px;
    outline: none;
  }
  
 .usedTiresForm-container button[type="submit"]:hover {
    background-color: #00509e;
  }
  
  
  .message {
    color: #fff; /* Bootstrap danger color for messages */
  }
  
  .form {
    position: relative;
  }
  
  
  
  .close {
    position: absolute;
    top: 0;
    right: 0;

    cursor: pointer;
  }
  

  
  .star {
    color: red; /* Use your accent color */
  }
  
  .order-detail {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .order-detail strong {
    font-weight: bold;
  }
  
  .order-detail span {
    margin-left: 5px;
  }


  .contact-message, .mandatory-note {
    margin-top: 20px;

    color: #fff;
    text-align: center;
    font-weight: bold;
    max-width: 685px;
  }

  .contact-message {
    width: 80%;
  }

  .textArea {
    margin-top: 10px;
    flex: 0 1 80% !important;
    max-width: unset !important;
  }

  .textArea textarea {
    padding: 5px;
    resize: none;
    height: 80px;
  }

  .order-preview {
    position: absolute;
    top: 0;
  }
  .order-preview p {
    display: inline;

    font-weight: bold;
  }


  .form-group input[type="checkbox"] {
    transform: scale(1.5); /* Increase size of checkbox */
    flex: 0 1 60%;
  }

  .checkbox-container {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  .checkbox-container label {
    margin-right: 10px;
  }

 .usedTiresForm-container .image-container {
     position: relative; 
     width: 100%; /* Adjust width as needed */ 
     margin: auto; 
     top: 20px;
    } 
  .image-container img { 
    width: 100%; /* Ensure the image fits within the container */ 
    height: auto; 
  } 
  .image-container .text { 
    position: absolute; 
    top: 53%; 
    left: 18%;
    transform:translate(-50%, -50%) perspective(600px) rotateX(2deg) rotateY(334deg); 
 /* Adjust font size as needed */ 
    color: white; 
    font-weight: bold; 
  }

/* Modal.css */
.alert-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* Darker overlay for better contrast */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.alert-modal-content {
  background: #fff; /* White background for the modal */
  padding: 30px; /* Increased padding for better spacing */
  border-radius: 10px; /* More rounded corners */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  text-align: center;
  max-width: 400px; /* Limit the width of the modal */
  width: 90%; /* Responsive width */
}

.alert-modal-content h2 {
  margin: 0 0 15px; /* Spacing below the heading */
 /* Larger font size for the heading */
  color: #333; /* Darker color for better readability */
  text-align: center;
}

.alert-modal-content p {
  margin: 0 0 20px; /* Spacing below the paragraph */
 /* Increased font size for better readability */
  line-height: 1.6; /* Increased line height for better spacing */
  font-weight: 400; /* Normal weight for the paragraph */
  color: #555; /* Softer, neutral color for the message */
  padding: 10px; /* Padding around the text */
  border-radius: 5px; /* Rounded corners for the paragraph background */
}

.alert-modal-content button {
  background-color: #003366; /* Bootstrap primary color */
  color: white; /* White text color */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners for the button */
  padding: 10px 20px; /* Padding for the button */
 /* Font size for the button */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.alert-modal-content button:hover {
  background-color: #114b84; /* Darker shade on hover */
}
  
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1; /* Ensure the overlays are above the background but below the content */
}

.overlay-filter {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black filter */
}

.overlay-image {
  background-image: url('../../public/images/underconstructionstamp.jpeg'); /* Replace with your overlay image path */
  background-position: center; /* Center the overlay image */
  opacity: 0.7; /* Adjust opacity as needed (0 to 1) */
  z-index: 10;
}
