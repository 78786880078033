.invoice-container {
    padding: 5px 20px;
   

}

.invoice-container p {
    font-size: 14px;
}

.invoice-container strong {
    font-size: inherit;
}
/* Modal Overlay */
.invoice-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure modal is above other content */
}

.invoice-modal-overlay .modal-content {
    background-color: #fff;
    width: 50%;
    padding: 0;
    max-width: 800px;
    font-family: Arial, sans-serif;
}

.invoice-header {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #000;
    margin-bottom: 20px;
}

.header-full-width {
    display: flex;
    justify-content: flex-end;
    
}

.header-left img {
    width: 150px;
}

.header-full-width p {
    margin: 0;
    font-size: 10px;
    margin-right: 15px;
}

.contact-info {
    max-width: 84%;
}

.header-left {
    width: 100%;
}

.contact-info p {
    margin: 0;
    font-size: 14px;
    line-height: 1.4;
}

.signature {
    position: relative;
    left: 25%;
}
.header-right {
    width: 100%;
}

.header-right p {
    margin: 0;
    line-height: 1.9;
    font-size: 14px;
}

.invoice-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin: 20px 0;
}

.invoice-prompt {
    text-align:center;
}



.invoice-info {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    position: relative;
}



.invoice-info p {
    margin: 0;
    font-size: 13px;
}

.invoice-bank-info {
    margin-bottom: 20px;
}

.invoice-bank-info p {
    margin: 0;
    font-size: 13px;
}



.note p {
    font-size: clamp(0.875rem, 1.3vw, 1.25rem);
}

.amount-table th , .amount-table td , .items th , .items td {
    font-size: clamp(0.875rem, 1.3vw, 1.125rem);
}

.important {
    border: 1px solid #000;
    margin-bottom: 20px;
    display: flex;
    gap: 4px;
}

.notice , .invoice-number {
    flex: 1;
}

.invoice-number {
    align-self: center;
    flex: 3.5;
    font-weight: bold;
    font-size: 13px;
}


.important .notice {
    background-color: #1da1f2;
    color: #000;
    font-weight: bold;
    padding: 10px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
}

.invoice-info .left,
.invoice-info .right {
    width: 48%;
}
.invoice-info .right {
    text-align: right;
}

.important .warning {
    background-color: transparent;
    border: none;
    margin-left: 0;
    font-weight: bold;
    flex: 6;
    font-size: 12px;
    align-self: center;
}

.red {
    color: red;
}

.items table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.items th, .items td {
    border: 1px solid #000;
    padding: 8px;
    text-align: left;
}

.amount-container {
    display: flex;
    justify-content: flex-end;
}

.amount-table {
    border-collapse: collapse;
}

.amount-table th, .amount-table td {
    border: 1px solid #000;
    padding: 8px;
    text-align: left;
}

.instructions {
    margin-bottom: 20px;
    max-width: 50%;
    border: solid;
}

.instructions ul {
    margin: 0;
    padding-left: 20px;
}

.instructions li {
    font-size: 13px;
}

.invoice-footer {
    display:flex;
    justify-content: flex-end;
    flex-direction: column;
}

.invoice-footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.invoice-footer p {
    margin: 0;
    text-align: right;
    font-size: 14px;
}

.headers {
    display: flex;
    justify-content: space-between;
    padding-bottom:5px ;
    align-items: flex-start;
}

.icon-paragraph {
    display: flex;
    align-items: center;
}

.header-left {
    flex: 2;
}

.header-right {
    flex: 1;
}

.note-amount-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-size: 14px;
}

.note-amount-container p {
    margin: 0;
    line-height: 1.2;
    font-size: 14px;
}

.instructions p {
    font-size: 16px;
}
.note-amount-container .note {
    max-width: 50%;
    border: solid;
}

.right img {
    position: absolute;
    top: 0;
    right: 5%;
}

/* Close Button */
.modal-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    z-index: 50;
}

.invoice-container .icon {
    color: #1e3a8a;
    margin-right: 3px;
}

.invoice-container .phone-code {
    top: unset;
}

.header-right .company-name {
    font-size: 18px;
}

.action-buttons {
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
    gap: 10px; /* Space between the buttons */
    margin-top: 20px; /* Add some spacing above the buttons */
}

.action-buttons button {
    background-color: var(--secondary-color); /* Primary button color */
    color: #fff; /* White text color */
    border: none; /* Remove default border */
    border-radius: 5px; /* Rounded corners */
    padding: 10px 15px; /* Add padding */
    font-size: 14px; /* Font size */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.action-buttons button:hover {
    background-color: var(--primary-color); /* Darker shade on hover */
}

.action-buttons button:active {
    background-color: var(--primary-color); /* Even darker shade when active */
    transform: scale(0.98); /* Slight scale effect when clicked */
}

.action-buttons button:focus {
    outline: none; /* Remove focus outline */
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5); /* Add focus shadow */
}

.signature-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.note p:last-child {
    padding: 0 20px;
}


.spinner-overlay {
    position: absolute; /* Position it relative to the parent container */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex; /* Center the spinner */
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
    z-index: 999; /* Ensure it's on top of other elements */
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(0, 0, 0, 0.1); /* Light border */
    border-top-color: #3498db; /* Accent color */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  .compatibility-message {
    background-color: #fff3cd;
    color: #856404;
    padding: 10px;
    border: 1px solid #ffeeba;
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 14px;
  }
  
  .pdf-preview-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    z-index: 9999;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .pdf-preview-modal iframe {
    border: none;
  }
  
  .warning-notice{ 
    background-color: #f9f9f9;
    border-left-color: #1da1f2;
    border-left-width:5px ;
    padding: 10px;
    
  }



  .warning-notice p {
    margin: 0;
    font-size: 14px;
  }

  /* Edit Button Styles */
.edit-button {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #2c3e50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .edit-button:hover {
    background-color: #1abc9c;
  }
  
  /* Bank Note Display Styles */
  .bank-note-display {
    padding: 10px;
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    border-radius: 5px;
    white-space: pre-wrap; /* Preserve line breaks */
    
  }

  .bank-note-wrapper {
  padding-top: 5px; /* Add margin to the wrapper */
}

#bankNoteLabel {
    background-color: unset;
}

#bankNoteInput {
    padding: 20px 10px;
}

@media screen and (max-device-width: 900px) {
    .invoice-bank-info p {
        font-size: 11px;
    }
}