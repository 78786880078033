/* Ensure all elements use border-box sizing */
*,
*::before,
*::after {
    box-sizing: border-box;
}

html, body {
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
    height: 100%; /* Ensure the body and HTML element take full height */
}

/* Slider container adjustments */
.slider-container {
    display: flex;
    justify-content: space-between; /* Space between slider and right image */
    width: 100%; /* Ensure container takes full width */
    max-width: 100%; /* Ensure no overflow */
    margin: 0 auto;
}

/* The slider area */
.slider {
    width: 80%; /* Make the slider take up 80% of the container width */
    overflow: hidden; /* Prevent overflow */
}

/* Right image container */
.right-image {
    max-width: 300px; /* Set a maximum width for the image */
    position: relative;
    text-align: center; /* Optional: Center the image inside the container */
    margin-left: 3px;
}

.right-image img {
    display: block;
}


.slick-list img {      /* Ensure the image fills the container width */
    height: auto;       /* Maintain aspect ratio */
    object-fit: cover;  /* Ensure the image covers the container without distortion */
    display: block;     /* Prevent any inline spacing below the image */
  }
  

/* Optional: Adjust position of slider dots */
.slick-dots {
    bottom: -30px; /* Adjust the position of the dots */
}

@media screen and (min-width:1300px) {
    /* On large screens, ensure images scale well */
    .slick-slide img {
        height: auto; /* Ensure images don't stretch vertically */
        object-fit: fill;
        width: 100%;
    }
}
