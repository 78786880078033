/* Container Styling */

/* Calculator Wrapper - New flex layout */
.calculator-wrapper {
  display: flex;
  gap: 30px;
  max-width: 1400px;
  margin: 20px auto;
  padding: 0 20px;
  flex-grow: 1;
}


.calculator-container {
    flex: 3;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    min-width: 55%; /* Ensures it doesn’t get too small */

  }
  
  /* Headings */
 .calculator-container h2,.calculator-container h5 {
    text-align: center;
    color: #1e3a8a;
  }
  
  h3 {
    margin-top: 30px;
  }
  
  /* Input Section */
  .input-section {
    margin-bottom: 20px;
  }
  
  .input-section label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .input-section input , .input-section select{
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  /* Table Styling */
  .cut-selection-table,
  .optional-table table {
    width: 100%;
    border-collapse: collapse;
    margin: 0;
  }

  .cutting-section-container{
    margin: 20px 0;
  }
  
  .cut-selection-table th, 
  .cut-selection-table td,
  .optional-table th, 
  .optional-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }
  
  .cut-selection-table th, .optional-table th {
    background-color: #1da1f2;
    font-weight: bold;
    color: white;
  }
  
  .cut-selection-table tr:nth-child(even),
  .optional-table tr:nth-child(even) {
    background-color: #f1f1f1;
  }
  
  .cut-selection-table tr:hover,
  .optional-table tr:hover {
    background-color: #f9f9f9;
  }
  
  /* Checkbox Cell */
  .checkbox-cell {
    text-align: center;
  }
  
  /* Selection Section */
  .selection-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
  }
  

  .cost-breakdown {
    flex: 2;
  }
  .optional-table table {
    background-color: white;
    border-radius: 6px;
    overflow: hidden;
  }
  
  /* Selection Column */
  .selection-column {
    flex: 1;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 6px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .selection-column h3 {
    text-align: left;
    color: #1e3a8a;
    margin-bottom: 15px;
  }
  
  .selection-column label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 14px;
  }
  
  .selection-column input[type="radio"] {
    margin-right: 10px;
  }
  
  /* Cost Breakdown */
  .breakdown-item {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .total-cost {
    font-size: 1.2rem;
    font-weight: bold;
    color: #2d9cdb; /* Strong blue for high contrast */
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 10px;
    background-color: rgba(45, 156, 219, 0.1); /* Lighter blue background */
    border-radius: 6px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adding a subtle shadow for depth */
}

  
  /* Button Styling */
 .calculator-container button {
    padding: 10px 20px;
    background-color: #1e3a8a;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    width: fit-content;
  }

  .button-section {
    display: flex;
    gap: 10px;
  }
  
 .calculator-container button:hover {
    background-color: #1da1f2;
  }
  
  .component-cell {
    text-align: left;
    padding-left: 8px;
  }
  
  .collapse-btn {
    cursor: pointer;
    width: fit-content;
    padding: 10px;
    border: solid;
    margin: 0;
  }

  .collapse-btn:hover {
    background-color: #f1892b;
    color: #f9f9f9;
  }

  .upper-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


.calculator-wrapper {
  position: relative;
}

  