.used-tires-page {
    margin: 0 auto;
    color: #333;
  
}


/* Hero Section */
.hero-section {
  background: url('../../public/images/used-tires-banner.jpeg') center/cover no-repeat;
  color: #fff;
  padding: 80px 20px;
  text-align: center;
  min-height: 250px; /* Minimum height for small screens */
  position: relative;
  max-height: 55vh; /* Constrain maximum height for consistency */
}

.hero-title {

  font-weight: bold;
  margin-bottom: 20px;
}   

.hero-content{
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.order-now-btn {
  background-color: #e67e22; 
  color: white; /* White text */
 /* Text size */
  padding: 12px 24px; /* Padding for top/bottom and left/right */
  border: none; /* No border */
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: transform 0.3s ease;
  z-index: 5;
  position: relative;
}

.order-now-btn a {
  text-decoration: none;
  color: inherit;
}

.order-now-btn:hover {
  background-color: #eb842a; /* Darker green on hover */
}

.order-now-btn:focus {
  outline: none; /* Remove focus outline */
}

/* Apply pulsing animation directly on the button */
.resume-animation {
  animation: pulse 2s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1); /* Slight pulsing effect */
  }
  100% {
    transform: scale(1);
  }
}


/* Overall container for the order steps */
.order-steps-container {
  padding: 40px 20px;
  text-align: center;
}

/* Heading styling */
.order-steps-container h1 , .reasons-wrapper h1, .tireRating-title, .tire-width-container h1 , .tire-depth h2 {

  color: #1e377f;
  margin-bottom: 40px;
  font-weight: bold;
  text-transform: uppercase; /* More emphasis */
  text-align: center;
}

/* Styling for individual step cards */
.step-card {
  background-color: #fff;
  padding: 30px;
  margin: 20px 0;
  border-left: 5px solid #f1892b;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  flex: 1; /* Ensures equal width for each card */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 8px; /* Rounded corners */
}

.step-card:hover {
  transform: translateY(-5px); /* Slight lift effect */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); /* Deeper shadow on hover */
}

.step-card h2 {

  color: #f1892b;
  margin-bottom: 20px;
  font-weight: bold;
}

.step-card p {

  color: #555;
  line-height: 1.8;
  margin-bottom: 20px;
}

/* List style inside each step */
.step-card ul {
  padding-left: 30px;
  list-style-type: disc;
  color: #555;
  list-style-position: inside;
}

.step-card li {

  line-height: 1.8;
  margin-bottom: 12px;
}

/* Styling for the container holding all steps */
.order-steps-container .steps {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
  margin-bottom: 40px;
}


.order-call-to-action p {

  color: #333;
  margin-bottom: 20px;
}

/* Call-to-action styling */
.order-call-to-action {
    margin-top: 30px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 20px auto;
    max-width: 1000px;
}



.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto; /* Allows scrolling if the content is taller than the screen */
}

.modal-content {
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  max-height: 90vh; /* Ensures the modal height doesn’t exceed 90% of the viewport height */
  overflow-y: auto; /* Adds scroll if content is too tall */
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;

  cursor: pointer;
  color: #f1892b;
  z-index: 54;
  border-radius: 90px;
  background-color: #fff;
}


/* General Styles */
.order-steps-container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  max-width: 1450px;
  margin: 0 AUTO;
}

.order-steps-container {
  flex-direction: column;
}

.reason {
  position: relative;
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid #f1892b;
  padding: 20px;
  width: 300px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
  text-align: center; /* Center-align content */
}

/* Image Styling */
.reason img {
  width: 100%; /* Make image full width of container */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Rounded corners */
  margin-bottom: 15px; /* Space between image and text */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); /* Subtle shadow */
}

/* Text Styles */
.reason h2 {
  color: #f1892b;
  font-size: clamp(1.75rem, 2vw, 2.375rem);
  font-weight: bold;
  margin-bottom: 10px;
}

.reason p {
  color: #333;

  line-height: 1.2;
  margin-bottom: 10px;
}

/* Hover Effect */
.reason:hover {
  transform: scale(1.05);
}


/* tire ratings */
.tire-rating-container {
  max-width: 1450px;
  margin: 0 auto;
  padding: 20px;

}

.tireRating-title {
  text-align: center;
  margin-bottom: 30px;

  font-weight: bold;
}

.rating-grid {
  display: flex;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Responsive grid */
  gap: 20px; /* Space between cards */
}

.rating-card {
  background-color: #ffffff;
  padding: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.rating-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.rating-title {
  color: #1da1f2;
  margin-bottom: 15px;
  font-size: clamp(1.75rem, 2vw, 2.375rem);
  font-weight: 600;
}

.rating-details {
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-grow: 1; /* Allow the list to grow and fill space */
}

.rating-details li {
  margin: 10px 0;
  color: #555;
  padding: 12px;
  background-color: #fff; /* Light background for list items */
  border-left: 4px solid #1da1f2; /* Blue left border */
  transition: background-color 0.3s, transform 0.3s;
  border-radius: 4px; /* Rounded corners */
}

.rating-details li:hover {
  transform: scale(1.02); /* Slightly scale up on hover */
}

.reasons-wrapper {
  max-width: 1450px;
  margin: 0 auto;
  padding: 20px;
}

.reasons {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

/* tire depth */
/* General styling for the container */
.tire-depth {
  margin: 20px 0;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Paragraph styles */
p {

  line-height: 1.6;
}

/* Table styles */
table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
}

th {
  background-color: #1da1f2;
  font-weight: bold;
  color: #fff;
  text-align: center;
}

.tables tbody tr:nth-child(even) {
  background-color: #fafafa;
}

.tables tbody tr:hover {
  background-color: #f1f1f1;
}

/* List styles */
ol {

  color: #555;
}

ol li {
  margin-bottom: 20px;
}

ul {
  padding-left: 20px;
  margin-top: 10px;
}

ul li {

}

/* Styling for strong text inside lists */
strong {
  font-weight: bold;
}

/* Add some spacing before and after the table */
table + h3 {
  margin-top: 40px;
}

/* Style for bullet points inside the list */
ul li {
  margin-bottom: 8px;
}

/* Responsive Design - Adjusting table layout for smaller screens */
@media (max-width: 768px) {
  table, th, td {

  }

  /* Stacking tables vertically for small screens */
  table {
    margin-bottom: 15px;
  }

  h2 {

  }

  h3 {

  }
}

.tables {
  display: flex;
  gap: 10px;
}

.passenger , .small-trucks {
  flex: 1;
}

/* TireWidth.css */
.tire-width-wrapper {
  padding: 20px;
}
.tire-width-container {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  max-width: 1450px;
  margin: 0 auto;
}

.additional-notes {
  box-shadow:0 4px 15px rgba(0, 0, 0, 0.1) ;
  padding: 20px;
}


.tire-width-container p {

  margin-bottom: 15px;
  line-height: 1.6;
  color: #555;
}

.tire-width-container ol {
  margin-left: 20px;
}

.tire-width-container li {
  margin-bottom: 10px;

  color: #444;
  line-height: 1.5;
}

.carDismantling-container .order-call-to-action {
  max-width: 100%;
}