.logistics-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.section {
    width: 50%;
    text-align: center;
}
.section img {
    margin-bottom: 20px;
}
.section h5 {
    font-weight: bold;
    margin-bottom: 10px;
}
.section p {
    line-height: 1.5;
    margin: 5px 0;
}

.skybridge {
    max-width: 120px;
}

.tanzania-agent {
    background-image: url('../../public/images/localServices/tanzaniaagentnew.png'); /* Replace with your image path */
    margin: 40px 0;
    background-position: top center;
    height: auto;
}   


.tanzania-agent .services-text {
    left: 20px;
}