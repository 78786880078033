.shipping-container {
    margin: 0 auto;
    overflow: hidden; /* Prevent overflow of the container */
    justify-content: flex-start;
    flex: 1;
}

.shipping-container .header {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: white;
    border-bottom: 1px solid #ccc;
    margin: 0 46px;
}

#list table {
    margin-top: unset;
    margin-bottom: unset;
}

.shipping-container .header h1 {
    position: unset;
    text-shadow: none;
}

.header img {
    margin-right: 10px;
}

.header h1 {

    color: #333;
    margin: 0;
}

.search-button {
    margin-left: auto;
    background-color: #f1892b;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
}

.content {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.content a {
    color: #4B0082;
    text-decoration: none;

    margin: 10px 0;
    display: block;
}

.shipping-table th, .shipping-table td {

}

.shipping-table-container {
    overflow: auto;
    height: 70vh;
}

.sticky-header {
    position: sticky;
    top: 0;
}

.roro td , .roro th {
    padding: 7px;
    text-align: center;
}

.content button {
    /* Your existing button styles */
    transition: background-color 0.3s ease;
  }
  
  .content button.active {
    background-color: #1da1f2; /* Example active state color */
    color: white;
    font-weight: bold;
  }

  .con td , .con th {
    padding: 9px;
  }

  .content button {
    background-color: #1e3a8a;
    color: #fff;
    font-weight: bold;
    padding: 10px;

  }

  .image-container img {
    object-fit: cover;
  }

  .content button:hover {
    background-color:#364c8b ;
  }

  @media  (max-width:900px) {

    .shipping-table th, .shipping-table td {
        font-size: 15px;
    }
    
  }
