/* Container for both Forgot Password and Reset Password pages */
.forgot-password-container,
.reset-password-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  padding: 20px;
}

h2 {
  margin-bottom: 20px;

  color: #333;
}

.forgot-password-container input[type="email"],
.reset-password-container input[type="password"] {
  width: 100%;
  max-width: 400px;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;

}

.forgot-password-container button , .reset-password-container button {
  width: 100%;
  max-width: 400px;
  padding: 12px;
  background-color: #1da1f2;
  color: white;
  border: none;
  border-radius: 4px;

  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.forgot-password-container button , .reset-password-container button:hover {
  background-color: #1e3a8a;
}

.forgot-password-container .message {
  margin-top: 20px;
  padding: 10px;
  text-align: center;

  border-radius: 4px;
  width: 100%;
  max-width: 400px;
}

.message.success {
  background-color: #d4edda;
  color: #155724;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
}

.forgot-password-container form,
.reset-password-container form {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 450px;
}

.forgot-password-container .message,
.reset-password-container .message {
  margin-top: 10px;
  text-align: center;
}

.forgot-password-container input[type="email"]:focus,
.forgot-password-container input[type="password"]:focus {
  border-color: #e67e22;
  outline: none;
}

button:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}
