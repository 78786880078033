.form-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: #fff;
  width: 80%;
}
.contact-container label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}
.contact-container input[type="text"],
.contact-container input[type="tel"],
.contact-container input[type="email"],
.contact-container select,
.contact-container textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}
.contact-container input[type="text"]::placeholder,
.contact-container input[type="email"]::placeholder,
.contact-container input[type="tel"]::placeholder,
.contact-container textarea::placeholder {
  color: #ccc;
}
 .required {
  color: red;
}

.submit-section button {
  width: 100%;

}
.contact-container button {
  padding: 10px;
  background-color: #1da1f2;
  border: none;
  border-radius: 5px;

  font-weight: bold;
  color: #fff;
  cursor: pointer;
}
.contact-container button:hover {
  background-color: #007bff;
}

.contact-container h2 {

  color: #1da1f2;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
}
.contact-container h3 {
  color: #333;
  text-align: center;
  margin: 20px 0;
}

.contact-prompt {
  margin-bottom: 25px;
}