.search-container-wrapper {
  display: flex;
}
.search-container {
  background-color: #1e3a8a;
  padding: 10px 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  overflow: hidden; /* Prevent overflow */
  width: 83%;
}

.search-container h4 {

  color: white;
  text-transform: uppercase;
  margin-bottom: 10px;
  border-bottom: 3px solid white;
  padding-bottom: 5px;
}

.search-form {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns for Make, Model, Type */
  grid-template-rows: auto auto auto; /* Three rows for input sections and button */
  gap: 10px; /* Adjust gap as needed */
  align-items: center;
}

.year-group {
  display: flex;
  margin: 0; /* Remove margin to prevent overflow */
  max-width: 100%;
  gap: 5px;
}


.year-group .form-group {
  flex: 1;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.search-container label {
  margin-bottom: 5px;
  color: #fff;
  font-weight: bold;
}

.form-group select,
.form-group input {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;

  width: 100%;
  box-sizing: border-box;
}

.search-btn {
  padding: 10px 20px;
  background-color: #333;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  justify-self: center; /* Center the button */
}

.search-btn:hover {
  background-color: #1485fe;
}

/* Style options when hovered */
.search-form select option:hover {
  background-color: #7d2020; /* Change to the desired hover color */
  color: #fff; /* Text color when hovering */
}



@media (max-width: 930px) {
  .year-group .form-group {
    flex: unset;
    margin: 0 4px;
  }
}