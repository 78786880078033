/* FAQComponent.css */

/* Container for the entire FAQ section */
.faq-container h2 {
    padding: unset;

  }

  /* Container for the language selector and label */
  .language-selector-container {
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
  }
  
  /* Label for the language selector */
  .language-label {
    display: block;
    margin-bottom: 8px;
    color: #555;
    font-weight: bold;
  }
  
  /* Language selector dropdown */
  .language-selector {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    width: 200px; /* Fixed width for better alignment */
  }
  
  /* Hover effect for the language selector */
  .language-selector:hover {
    border-color: #888;
  }
  
  /* Container for the list of questions and answers */
  .faq-list {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
  }
  
  
  /* Styling for the question */
  .faq-question {
    font-weight: bold;

    margin-bottom: 10px;
  }
  
  /* Styling for the answer */
  .faq-answer {

    line-height: 1.5;
  }