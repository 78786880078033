
  .enquiryContainer {
    width: 55%;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 600px;
  }
  .enquiryContainer h3 {
    text-align: left;
    color: #000;
    border-bottom: 2px solid #f1892b;
    padding-bottom: 5px;
}
.form-section {
    margin-bottom: 20px;
}
.enquiryContainer .form-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    flex-direction: row;
}
.enquiryContainer .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color:#000;
}
.form-group input, .form-group select, .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.form-group textarea {
    width: 100%;
    height: 100px;
}
.form-group .full-width {
    width: 100%;
}
.form-group .half-width {
    width: 48%;
}
.form-group .quarter-width {
    width: 48%;
}
.form-group .third-width {
    width: 31%;
}
.form-group .small-width {
    width: 48%;
}
.form-group .info-icon {
    margin-left: 5px;
    color: #17a2b8;
    position: absolute;
    margin-top: 15px;
    display: none;
}
.form-group .info-icon:hover {
    cursor: pointer;
}
.submit-section {
    text-align: center;
    margin-top: 20px;
}
.submit-section button {
    background-color: #f1892b;
    color: #fff;
    padding: 15px 30px;
    border: none;
    border-radius: 4px;
    width: unset;
    cursor: pointer;
}
.submit-section button:hover {
    background-color: #ec971f;
}
.checkbox-section {
    text-align: center;
    margin-top: 10px;
}
.checkbox-section input {
    margin-right: 5px;
}
.required-star {
    color: red;
}

textarea {
    resize: none;
}


.message-status {
    margin-top: 20px;
    padding: 15px;
    border-radius: 5px;
    text-align: center;

    font-weight: bold;
    transition: opacity 0.5s ease;
  }
  
  .message-status.success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }
  
  .message-status.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
  }
  
  .login-note {
    background-color: #f9f9f9; /* Light background color */
    border: 1px solid #ccc; /* Light border */
    border-radius: 5px; /* Rounded corners */
    padding: 15px; /* Padding around the content */
    margin: 20px 0; /* Margin above and below the note */
    display: flex; /* Flexbox for layout */
    align-items: center; /* Center items horizontally */
    text-align: center; /* Center text */
    justify-content: center;
    gap: 10px;
}

.login-note button {
    background-color: #1da1f2; /* Bootstrap primary color */
    color: white; /* White text */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    padding: 7px 12px; /* Padding for the button */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    text-transform: lowercase;
}

.login-note button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

