.footer {
  background-color: #1da1f2;
  color: white;
  padding: 20px;
  text-align: center;

  position: relative;
  bottom: 0;
  z-index: 200;
}


.info h4 {
  color: #fff;
}

.footer-upper {
  background-color: #1e3a8a;
  color: white;
  padding: 20px;
  display: flex;
  align-items: center;

}
.footer-upper img {
  margin-right: 20px;
}

.footer-inner {
  padding: 0 46px;
}
.header .info {
  line-height: 1.6;
}

.footer-upper a {
  color: inherit;
}


