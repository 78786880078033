.car-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 20px;
  }
  
  .car-details img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .car-details-info {
    display: flex;
    flex-direction: column;
  }
  
  .car-details-info h2 {

    margin-bottom: 10px;
  }
  
  .car-details-info p {

    color: #666;
    margin-bottom: 20px;
  }
  
  .car-details-info button {
    background-color: #1e3a8a;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .car-details-info button:hover {
    background-color: #2d4ca3;
  }
  
  /* Responsive */
  @media (min-width: 768px) {
    .car-details {
      grid-template-columns: 1fr 1fr;
    }
  }
  