.about-container {
    padding: 40px;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    margin: 0 auto;
    width: 60%;
  }
  
  h2 {
 
    margin-bottom: 20px;
    color: #2c3e50;
  }
  
  .about-container p {

    color: #34495e;
    margin-bottom: 20px;
  }
  
  .brand-name {
    font-weight: bold;
    color: #e74c3c;
  }
  
  
  .team-image, .world-map {
    width: 100%;
    max-width: 600px;
    margin-top: 20px;
    border-radius: 10px;
  }
  
  /* Why Choose Us */
  .features {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 30px;
  }
  
  .feature-item {
    flex: 1;
    min-width: 250px;
    text-align: center;
    padding: 20px;
    border: 1px solid #ecf0f1;
    background-color: #f8f9fa;
    border-radius: 10px;
  }
  
  .feature-item i {

    color: #2980b9;
    margin-bottom: 10px;
  }
  
  .feature-item h4 {

    color: #34495e;
    margin-bottom: 10px;
  }
  
  /* Testimonials */
  .testimonials {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 10px;
  }
  
  blockquote {
    font-style: italic;
    color: #7f8c8d;

  }
  
   .about-container span {
    font-weight: bold;
    color: #2c3e50;
    margin-top: -10px;
  }
  
  /* Global Presence Map */
  .world-map {
    width: 100%;
    max-width: 600px;
    margin-top: 20px;
    border-radius: 10px;
  }
  
  /* Sustainability Section */
  .sustainability {
    background-color: #ecf0f1;
    padding: 20px;
    border-radius: 10px;
  }

  
  
  @media (max-width: 768px) {
    .features {
      flex-direction: column;
    }
    
    
    .about-container {
      padding: 20px;
    }
  
    h2 {

    }
  
    p {

    }
  }
  
  